<div class="pt-5 lg:pr-56 flex flex-col lg:flex-row justify-between items-center">
    <div class="flex items-center">
        <img [src]="logo" alt="Illustration" class="h-16 w-72 mb-5 lg:mb-0" />
        <!-- <mat-icon *ngIf="isMobileView" class="px-3" (click)="toggleMenu()">menu</mat-icon> -->
    </div>
    <!-- <div class="flex flex-col lg:flex-row text-white">
        <div *ngIf="isMenuOpen" class="flex flex-col lg:flex-row">
            <button class="px-3 mb-2 lg:mb-0">Home</button>
            <button class="px-3 mb-2 lg:mb-0">About us</button>
            <button class="px-3 mb-2 lg:mb-0">Services</button>
            <button class="px-3 mb-2 lg:mb-0">Resources</button>
            <button class="px-3 mb-2 lg:mb-0">Contact us</button>
        </div>
    </div>
    <button *ngIf="!isMobileView" type="button" class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2">
        Get Started
    </button> -->
</div>