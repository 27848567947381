
<div class=" bg-img h-full bg-black  text-white md:pl-20 lg:pl-56 pb-10 overflow-x-hidden fixed">
    <app-header></app-header>

    <div class="flex flex-col md:flex-row">
        <div class="md:w-full lg:w-2/4 pt-10 md:pr-10 lg:pr-0 pl-6 pr-6 md:pr-2 lg:pr-0">
            <span class="font-medium text-5xl">
                Empowering the world through the most sophisticated technologies, simplified.
            </span>
    
            <div class="text-lg font-light pt-8 pb-8">
                We are a technology company based out of India with a simple mission - get technology to solve problems we never thought are solvable. Be it software, hardware, cryptography, or robotics, we firmly believe that technology, when combined with the right intent and spirit, can make the world a much better place to live, and that is the mission we are committed to.
            </div>
    
            <!-- <button type="button" class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 me-2 mb-2">
                Get Started
            </button> -->
        </div>
    
        <!-- <div class="md:w-full lg:w-2/4 pl-4 md:pl-2 lg:pl-0 mt-6 md:mt-0">
            <img class="object-cover h-full lg:h-full w-full md:block md:h-auto" src="https://f005.backblazeb2.com/file/iaddd6/TechIllustrator.png" alt="Tech Illustrator Image" />
        </div>           -->
        <div class="md:w-full lg:w-2/4 pl-4 md:pl-2 lg:pl-0 mt-6 md:mt-0">
            <img [src]="techllustratorimage" alt="Illustration" class="object-cover h-full lg:h-full w-full md:block md:h-auto" />
        </div>
    
    </div>

    <div class="pb-10 md:pr-10 lg:pr-0 pl-6 pr-6 md:pr-2 lg:pr-0 text-white font-medium text-4xl">
        Powerful features to help you manage all your leads
    </div>

    <div class="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 pr-8 pl-8 lg:pr-56">
        <div *ngFor="let service of services" class="cursor-pointer gap-6 p-4 flex text-white rounded-lg border bg-black bg-opacity-70" style="border-color: rgba(217, 224, 225, 0.5);">
            <div>
                <img [src]="service.imagePath" class="h-15 w-36" />
            </div>
            <div>
                <div class="text-2xl font-medium">{{ service.name }}</div>
                <div class="py-3 text-sm font-extralight">{{ service.description }}</div>
                <button type="button" [disabled]="service.disabled" matTooltip="Coming Soon" matTooltipPosition="above" class="text-white bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-300 me-2 mb-2">
                    <mat-icon>language</mat-icon>
                    <span class="px-3">
                        Explore
                    </span>
                    <mat-icon>arrow_upward</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="flex flex-col lg:flex-row pl-5 pt-16 pr-5 lg:pr-56">
        <div class="flex-1 lg:w-1/2 pr-0 lg:pr-8 pb-5">
            <div class="font-medium text-4xl">
                If you have any questions or concerns regarding our products, please don't hesitate to contact us
            </div>
            <div class="text-white text-sm font-extralight pt-5">
                Interested in collaborating? Or just curious? As an investor, developer, or media? Feel free to reach out to us, or just drop a message. We are always listening!
            </div>
            <div class="pt-16">
                <div class="flex">
                    <div>
                        <span class="cursor-pointer p-2 flex bg-slate-900 rounded-lg items-center"><mat-icon> mail_outline</mat-icon></span>
                    </div>
                    <div class="pl-3">
                        <span class="text-base font-medium">Email address</span>
                        <p class="pt-1 text-sm font-light">{{emailname}}</p>
                    </div>
                </div>
                <div class="flex pt-8">
                    <div>
                        <span class="cursor-pointer p-2 flex bg-slate-900 rounded-lg items-center"><mat-icon>phone</mat-icon></span>
                    </div>
                    <div class="pl-3">
                        <span class="text-base font-medium">Phone number</span>
                        <div class="pt-1 text-sm font-light">{{mobile}}</div>
                    </div>
                </div>
                <div class="flex pt-8">
                    <div>
                        <span class="cursor-pointer p-2 flex bg-slate-900 rounded-lg items-center"><mat-icon>location_on</mat-icon></span>
                    </div>
                    <div class="pl-3">
                        <span class="text-base font-medium">Address</span>
                        <div class="pt-1 text-sm font-light">{{address}}</div>
                    </div>
                </div>
            </div>
        </div>

        <form (ngSubmit)="onSubmit()" class="border rounded-lg w-full lg:w-1/2 bg-black bg-opacity-70 pt-10 pl-8 lg:pl-16 pb-10 lg:pr-8 lg:ml-0" style="border-color: rgba(217, 224, 225, 0.5);">
            <span class="font-normal text-2xl pl-8 lg:pl-0">Contact us</span>
            <div class="pt-5 px-8 lg:px-0 lg:pr-8">
                <div class="flex relative rounded">
                    <button class="px-1 h-12 flex border border-r-0 rounded-tl-lg rounded-bl-lg focus:outline-none bg-black bg-opacity-70 top-0 left-0">
                        <mat-icon class="w-5 h-5 self-center">person_outline</mat-icon>
                    </button>
                    <input [(ngModel)]="name" name="name" class="cursor-input w-full lg:w-full left-8 border border-l-0 bg-black bg-opacity-70 border-1 rounded-tr-lg rounded-br-lg outline-none px-2 caret-white text-white font-light text-base placeholder-white" type="text" placeholder="Name" />
                </div>
                <div class="flex pt-5 relative rounded">
                    <button class="px-1 h-12 flex border border-r-0 rounded-tl-lg rounded-bl-lg focus:outline-none bg-black bg-opacity-70 top-0 left-0">
                        <mat-icon class="w-5 h-5 self-center">mail_outline</mat-icon>
                    </button>
                    <input [(ngModel)]="email" name="email" class="cursor-input w-full lg:w-full left-8 border border-l-0 bg-black bg-opacity-70 border-1 rounded-tr-lg rounded-br-lg outline-none px-2 caret-white text-white font-light text-base placeholder-white" type="text" placeholder="Email Address" />
                </div>
                <div class="flex pt-5 relative rounded text-white">
                    <button class="px-1 h-12 flex border border-r-0 rounded-tl-lg rounded-bl-lg focus:outline-none bg-black bg-opacity-70 top-0 left-0">
                        <mat-icon class="w-5 h-5 self-center">phone</mat-icon>
                    </button>
                    <input [(ngModel)]="phoneNumber" name="phoneNumber" class="cursor-input w-full lg:w-full left-8 border border-l-0 bg-black bg-opacity-70 border-1 rounded-tr-lg rounded-br-lg outline-none px-2 caret-white text-white font-light text-base placeholder-white" type="text" placeholder="Phone number" />
                </div>
                <div class="relative pt-5 rounded">
                    <textarea [(ngModel)]="message" name="message" class="h-32 pt-10 self-center cursor-input w-full lg:w-full border bg-black bg-opacity-70 border-1 rounded outline-none px-10 caret-white text-white font-light text-base placeholder-white" placeholder="Message"></textarea>
                    <div class="absolute inset-y-0 left-0 flex items-center px-2 text-white self-center">
                        <mat-icon class="w-5 h-5">message</mat-icon>
                    </div>
                </div>         
                
                <div class="pt-5 flex justify-end">
                    <button type="submit" class="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex dark:focus:ring-[#1da1f2]/55 me-2 mb-2">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>
