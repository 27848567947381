import { Component, HostListener, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../../environments/environment.development';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule,MatIconModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  isMobileMenuOpen: boolean = false;
  logo = environment.imageurl+'darklogo.png';

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  isMenuOpen = false;
  isMobileView = false;

  constructor() {
    console.log(environment.imageurl);
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    if (typeof window !== 'undefined') {
      this.isMobileView = window.innerWidth <= 768; // Adjust this threshold as needed
      if (!this.isMobileView) {
        this.isMenuOpen = true; // Always show the menu on large screens
      }
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  
}
