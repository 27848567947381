// import { Component } from "@angular/core";
// import { HeaderComponent } from "../header/header.component";
// import { MatIconModule } from "@angular/material/icon";
// import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { CommonModule } from "@angular/common";
// import { MatTooltipModule } from "@angular/material/tooltip";
// import { MatSnackBar } from "@angular/material/snack-bar";
// import { environment } from "../../environments/environment.development";
// import { ApiService } from "../api.service";

// interface Service {
//   name: string;
//   description: string;
//   imagePath: string;
//   disabled: boolean;
// }

// @Component({
//   selector: "app-home",
//   standalone: true,
//   imports: [
//     HeaderComponent,
//     MatIconModule,
//     FormsModule,
//     MatTooltipModule,
//     CommonModule,
//   ],
//   templateUrl: "./home.component.html",
//   styleUrl: "./home.component.scss",
// })
// export class HomeComponent {
//   name: string = "";
//   email: string = "";
//   phoneNumber: string = "";
//   message: string = "";
//   emailname: string = "official@orthoquant.com";
//   mobile: string = "080-61770944";
//   address: string =
//     "WeWork Prestige Atlanta, 80 Feet Main Road, Industrial Layout, Bangalore South, Bangalore, Karnataka, India - 560034.";
//   techllustratorimage = environment.imageurl + "TechIllustrator.png";
//   resmessage: any;
//   constructor(private snackBar: MatSnackBar, private apiService: ApiService) {}

//   services: Service[] = [
//     {
//       name: "Web Search",
//       description:
//         "A privacy oriented deep search engine that answers what you ask, directly!",
//       imagePath: environment.imageurl + "enquare.png",
//       disabled: true,
//     },
//     {
//       name: "Business Search",
//       description:
//         "A search portal for everything related to finance - numbers and news alike!",
//       imagePath: environment.imageurl + "bizquare.png",
//       disabled: true,
//     },
//     {
//       name: "Geospatial Search",
//       description:
//         "A search portal for all things geospatial - from points of interest to routing to weather!",
//       imagePath: environment.imageurl + "mapquare.png",
//       disabled: true,
//     },
//     {
//       name: "Image Search",
//       description:
//         "A search portal for images of everything and everyone, in all shapes, sizes and colors!",
//       imagePath: environment.imageurl + "picquare.png",
//       disabled: true,
//     },
//     {
//       name: "News Search",
//       description:
//         "A search portal for all latest and breaking news on every topic all around the world!",
//       imagePath: environment.imageurl + "newsquare.png",
//       disabled: true,
//     },
//     {
//       name: "Chatbot",
//       description:
//         "The world's most advanced chatbot that answers anything and everything you are curious about!",
//       imagePath: environment.imageurl + "omniscienticon.png",
//       disabled: true,
//     },
//   ];

//   // constructor(
//   //   private formBuilder: FormBuilder,
//   //   private http: HttpClient
//   // ) {
//   //   this.contactForm = this.formBuilder.group({
//   //     name: ['', Validators.required],
//   //     email: ['', [Validators.required, Validators.email]],
//   //     phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
//   //     message: ['', Validators.required]
//   //   });
//   // }

//   onSubmit() {
//     const jsonData = {
//       name: this.name,
//       sender_email: this.email,
//       phone: this.phoneNumber,
//       message: this.message,
//     };

//     this.apiService.submitForm(jsonData).subscribe((response: any) => {
//       console.log("Response from server:", response);
//       this.resmessage = response;
//       this.snackBar.open(this.resmessage.message, "Close", {
//         duration: 6000, // Snackbar duration in milliseconds
//       });

//       // Clear form fields
//       this.name = "";
//       this.email = "";
//       this.phoneNumber = "";
//       this.message = "";
//     });
//   }
// }



import { Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment.development';
 
interface Service {
  name: string;
  description: string;
  imagePath: string;
  disabled: boolean;
}
 
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [HeaderComponent, MatIconModule,FormsModule,MatTooltipModule,CommonModule,HttpClientModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
 
   name: string = '';
  email: string = '';
  phoneNumber: string = '';
  message: string = '';
  emailname: string ='official@orthoquant.com';
  mobile: string= '080-61770944';
  address: string= 'WeWork Prestige Atlanta, 80 Feet Main Road, Industrial Layout, Bangalore South, Bangalore, Karnataka, India - 560034.'
  techllustratorimage = environment.imageurl + 'TechIllustrator.png';
  resmessage : any
  constructor(private http:HttpClient,  private snackBar: MatSnackBar) {}
 
  services: Service[] = [
    {
      name: 'Enquire',
      description: 'A privacy oriented deep search engine that answers what you ask, directly!',
      imagePath:  environment.imageurl+'enquare.png',
      disabled: true
    },
    {
      name: 'Bizquire',
      description: 'A search portal for everything related to finance - numbers and news alike!',
      imagePath: environment.imageurl+'bizquare.png',
      disabled: true
    },
    {
      name: 'Mapquire',
      description: 'A search portal for all things geospatial - from points of interest to routing to weather!',
      imagePath: environment.imageurl+'mapquare.png',
      disabled: true
    },
    {
      name: 'Picquire',
      description: 'A search portal for images of everything and everyone, in all shapes, sizes and colors!',
      imagePath: environment.imageurl+'picquare.png',
      disabled: true
    },
    {
      name: 'Newsquire',
      description: 'A search portal for all latest and breaking news on every topic all around the world!',
      imagePath: environment.imageurl+'newsquare.png',
      disabled: true
    },
    {
      name: 'Omniscient',
      description: 'The world\'s most advanced chatbot that answers anything and everything you are curious about!',
      imagePath: environment.imageurl+'omniscienticon.png',
      disabled: true
    }
  ];
 
 
  // constructor(
  //   private formBuilder: FormBuilder,
  //   private http: HttpClient
  // ) {
  //   this.contactForm = this.formBuilder.group({
  //     name: ['', Validators.required],
  //     email: ['', [Validators.required, Validators.email]],
  //     phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
  //     message: ['', Validators.required]
  //   });
  // }
 
 
    onSubmit() {
     
      const jsonData = {
        name: this.name,
        sender_email: this.email,
        phone: this.phoneNumber,
        message: this.message
      };
      // console.log("clicked",jsonData)
      const url = 'https://backend.orthoquant.com/mail/feedback/';
      this.http.post(url, jsonData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .subscribe(response => {
        // console.log('Response from server:', response);
        this.resmessage = response
        this.snackBar.open(this.resmessage.message, 'Close', {
          duration: 6000 // Snackbar duration in milliseconds
        });
 
        // Clear form fields
    this.name = '';
    this.email = '';
    this.phoneNumber = '';
    this.message = '';
        // Handle success
      }, error => {
        console.error('Error:', error);
        // Handle error
      });
    } 
}