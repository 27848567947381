<div class="hidden lg:block bg-black pb-10 pt-16 pl-5 pr-5 lg:pl-56 lg:pr-56">
 
    <div class="flex">
        <div class="flex-initial  w-2/5">
            <img [src]="orthoquantlogo" alt="Illustration" class="h-11 w-40" />
            <div class="w-4/5 pt-3 text-neutral-400 text-sm font-extralight">
                A technological company with one simple goal - making technology solve all complex problems and make human lives simpler, easier, and more fun!.
            </div>
        </div>
    
        <div class=" flex-1 flex justify-between space-x-2">
            <!-- <div class="hidden lg:block">
                <div class="text-white mb-4">PRODUCTS</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">How it works</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">What is Orthoquant?</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Why choose Orthoquant?</div>
            </div>
    
            <div class="hidden lg:block">
                <div class="text-white mb-4">COMPANY</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">About us</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Career</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Contact Us</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Features</div>
            </div>
    
            <div class="hidden lg:block">
                <div class="text-white mb-4">HELP</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Customer Support</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Privacy Policy</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Terms & Conditions</div>
            </div>
    
            <div class="hidden lg:block">
                <div class="text-white mb-4">RESOURCES</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Development Tutorials</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">Free eBooks</div>
                <div class="text-neutral-400 text-sm font-extralight mb-4">How to Blog</div>
            </div> -->
        </div>
    </div>
    
    <div class="hidden lg:block text-neutral-400">
        <!-- Using Tailwind CSS to create a full-width dashed line -->
        <div class="w-full h-0.5 bg-transparent border-t border-dashed border-neutral-300 my-4"></div>
    </div>
    
    <div class=" flex justify-between font-thin text-xs text-neutral-400">
        <div  class="order-1 mb-4">
            2024. All Rights Reserved
        </div>
 
        <div class="order-2 flex flex-wrap">
            <!-- <div  class="mb-4 pr-5">Sitemap</div>
            <div  class="mb-4">Cookie Settings</div> -->
        </div>
    </div>
 
 
</div>
 
 
<div class="bg-black pb-24 pt-6  flex justify-center lg:hidden">
    <div class="flex-initial w-4/5">
        <img [src]="orthoquantlogo" alt="Illustration" class="h-11 w-40 mb-4" />
        <div class="text-neutral-400 pt-5 text-sm font-extralight">
            A technological company with one simple goal - making technology solve all complex problems and make human lives simpler, easier, and more fun!.
        </div>
    </div>

    <div class=" text-neutral-400">
        <div style="width: 100%; height: 1px; background-color: transparent; border-top: 1px dashed #ccc; margin: 10px 0;"></div>
    </div>

</div>




